<template>
  <div>
    <v-col class="mx-1 mt-8  ">
      <v-card v-if="componentsize">
        <v-row class="px-2 mt-2 py-6">
          <v-spacer />

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <Button
                  :text="true"
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </Button>
                <Button
                  :text="true"
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </Button>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="santral"
              :items="santralList"
              label="Santral"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <MultiSelect
              v-model="combinerboxDCCurrentModel"
              :items="combinerboxDCCurrent"
              label="StringBox"

              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-slider
              v-model="hoursModel"
              :thumb-size="50"
              :max="max"
              min="0"
              thumb-label="always"
            >
              <template v-slot:thumb-label="{value}">
                {{ hourSelected[value] }}
              </template>
            </v-slider>
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            lg="2"
            xl="1"
            class="  py-0 my-0  mb-2"
          >
            <Button
              color="primary"
              @click="getDailyMinuteEnergy"
            >
              Filtrele
            </Button>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="string in stringBoxList[santral]"
            :key="string.value + santral"
            class="mr-5 ml-5 mb-5 mt-5"
          >
            <div
              :id="`${string.value}_stringbox`"
              :style="' height: 250px; min-width: 900px;padding: 1px;width:' + setWith[string.value].width"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import * as TimeOfStringbox from '@/api/CombinerBox/TimeOfStringbox';
import moment from 'moment';
import helper from '@/api/helper';

export default {
  name: 'Dashboard',
  data: () => ({
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),
    menu: false,
    modal: false,
    menu2: false,
    livehourdata: {},
    setWith: {},
    stringBoxList: {},
    combinerboxDCCurrentModel: [],
    combinerboxDCCurrent: [],
    measureList: [],
    hoursModel: 50,
    hourSelected: [],
    santralInfo: [],
    santral: '',
    stringPanelQuantity: {},
    componentsize: 1,
    component: 'a',
    width: '100%',
    selectHour: '',
    max: '0',
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    santralList() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralList = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });

      // eslint-disable-next-line consistent-return
      return santralList;
    },
    santralData() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralData = {};
      santralRawData.forEach((item) => {
        santralData[item.santralCode] = item;
      });
      // eslint-disable-next-line consistent-return
      return santralData;
    },
  },
  watch: {
    hoursModel() {
      if (this.hoursModel > 0) {
        this.get_getStringBoxTimeDc();
      }
    },

    santral() {
      const rest = this.santralInfo[this.santral];
      const combinerboxList = [];
      const combinerboxDCCurrentModel = [];

      let i = 0;
      Object.keys(rest).forEach((key) => {
        if (i < 5) {
          combinerboxDCCurrentModel.push(rest[key]);
        }
        combinerboxList.push({
          text: rest[key].STRINGBOXNAME,
          value: rest[key].STRINGBOX,
        });
        i += 1;
      });

      this.stringBoxList[this.santral] = combinerboxList;
      this.combinerboxDCCurrent = combinerboxList;
      this.combinerboxDCCurrentModel = combinerboxDCCurrentModel;
      const setWith = {};

      Object.keys(rest).forEach((k) => {
        const { stringQuantity } = rest[k];

        let width = '100%';
        if (stringQuantity <= 5) {
          width = '250px';
        } else if (stringQuantity > 5 && stringQuantity < 10) {
          width = '400px';
        } else {
          width = '100%';
        }

        setWith[k] = {
          width,
        };
      });

      this.setWith = setWith;
    },
  },
  created() {},
  mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    let k = 0;
    function myTimer1() {
      const { santralStringboxData, stepTime } = self.$store.getters;

      if (Object.keys(santralStringboxData).length > 0) {
        // eslint-disable-next-line no-plusplus
        k++;
        if (k > 30) {
          clearInterval(myVar1);
        }

        // eslint-disable-next-line prefer-destructuring
        self.santral = self.santralList[0].value;

        if (stepTime === undefined || stepTime === null) {
          // eslint-disable-next-line no-const-assign
          stepTime = 10;
        }

        self.santralInfo = santralStringboxData;
        const timeList = [];
        clearInterval(myVar1);
        for (let h = 5; h < 22; h += 1) {
          for (let mn = 0; mn < 60; mn += stepTime) {
            let hour;
            let mnt;

            // eslint-disable-next-line no-unused-expressions
            h < 10 ? (hour = `0${h}`) : (hour = `${h}`);
            // eslint-disable-next-line no-unused-expressions
            mn < 10 ? (mnt = `0${mn}`) : (mnt = `${mn}`);
            if (timeList.length < 201) {
              timeList.push(`${hour}:${mnt}`);
            }
          }
        }

        self.hourSelected = timeList;
        self.max = timeList.length.toString();
        self.selectHour = 20;
      }
    }
  },
  methods: {
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');

      const payload = {
        condiniton: {
          date: this.startDate,
          startHour: 5,
          finishHour: 21,
          santral: this.santralData[this.santral].santral,
          STRINGBOX: this.combinerboxDCCurrentModel,
          // column: ["STRINGBOX", "STRINGBOXKEY", "STRINGBOXNAME"],
          column: [],
        },
      };

      this.componentsize += 1;
      const tempData = TimeOfStringbox.default.getStringBoxTimeDc(payload);
      tempData.then((res) => {
        this.stringBoxData = res;
        this.get_getStringBoxTimeDc();
      });
    },

    get_getStringBoxTimeDc() {
      const combinerboxDCCurrentList = this.santralInfo[this.santral];
      const res = this.stringBoxData;
      const { hoursModel } = this;
      this.selectHour = this.hourSelected[hoursModel];

      const optionData = TimeOfStringbox.default.get_getStringBoxTimeDc(
        res[this.selectHour],
        combinerboxDCCurrentList,
      );

      const echartdom = {};
      const myChart = {};
      optionData.then((result) => {
        this.stringBoxList[this.santral].forEach((stringbox) => {
          if (!helper.is_Empty(result)) {
            const id = `${stringbox.value}_stringbox`;

            echartdom[id] = document.getElementById(id);
            myChart[id] = echarts.init(echartdom[id]);
            if (!helper.is_Empty(result[stringbox.value])) {
              myChart[id].setOption(result[stringbox.value]);
            }
          }
        });

        this.componentsize += 1;
      });
    },
  },
};
</script>
